import React ,{ Component }from "react";
import { FiShield, FiCode, FiCloud, FiGrid, FiUsers, FiCpu } from 'react-icons/fi';
import { FaBrain, FaProjectDiagram, FaChartLine, FaClipboardCheck, FaShippingFast, FaFlask } from 'react-icons/fa';

const ServiceList  = [
    {
        icon: <FaProjectDiagram />,
        title: 'Data Strategy & Consulting',
        description: 'We craft data-driven roadmaps aligned with your business objectives, identifying and prioritizing your data needs for optimal utilization.',
    },
    {
        icon: <FaFlask />,
        title: 'Food Fortification',
        description: 'Leverage data analytics to research the impact of fortification in Africa and provide stakeholders with reliable data sources.',
    },
    {
        icon: <FaChartLine />,
        title: 'Data-Driven Marketing',
        description: 'We offer Digital Marketing Services driven by data insights to optimize your outreach and engagement.',
    },
    {
        icon: <FaBrain />,
        title: 'AI-Powered Chatbots & Virtual Assistants',
        description: 'Develop intelligent chatbots and virtual assistants to enhance customer interactions and streamline processes.',
    },
    {
        icon: <FiUsers />,
        title: 'Freelance Projects',
        description: 'Access a pool of vetted freelance data scientists, Data Engineers, ML engineers, and Developers for your project-based needs.',
    },
    {
        icon: <FiShield />,
        title: 'Data Security & Privacy Solutions',
        description: 'Protect your data assets with comprehensive security solutions that ensure compliance and safeguard against breaches.',
    },
    {
        icon: <FaShippingFast />,
        title: 'Supply Chain Consulting',
        description: 'Optimize your supply chain through data-driven insights that improve efficiency and reduce operational costs.',
    },
    {
        icon: <FaClipboardCheck />,
        title: 'Software Testing & Quality Assurance',
        description: 'Ensure software reliability and performance with rigorous testing and quality assurance services.',
    },
    {
        icon: <FiCode />,
        title: 'IT Solutions: Web Development',
        description: 'Develop robust and scalable web applications tailored to your business needs.',
    },
    {
        icon: <FiCpu />,
        title: 'IT Solutions: App Development',
        description: 'Create user-friendly mobile applications that meet your business and customer needs.',
    },
    {
        icon: <FiCloud />,
        title: 'Cloud Solutions (AWS)',
        description: 'Leverage the power of AWS cloud to scale your business and enhance operational efficiency.',
    },
    {
        icon: <FiGrid />,
        title: 'UX/UI Design',
        description: 'Design intuitive and engaging user experiences that elevate your digital products.',
    },
];


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);

        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href="/#">
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
