import React, { Component, Fragment } from "react";
import Scrollspy from "react-scrollspy";
import ScrollToTop from "react-scroll-up";
import {FiChevronUp, FiX, FiMenu, FiHeadphones, FiMail, FiMapPin} from "react-icons/fi";
import { FiBarChart, FiDatabase, FiTrendingUp, FiPieChart, FiFileText } from 'react-icons/fi';
import ServiceList from "../elements/service/ServiceList";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import BlogContent from "../elements/blog/BlogContent";
import FooterTwo from "../component/footer/FooterTwo";
import Helmet from "../component/common/Helmet";

import logoLight from "../assets/images/logo/logo-light.png";
import logoAllDark from "../assets/images/logo/logo-all-dark.png";
import aboutImg from "../assets/images/about/2.webp";

const SlideList = [
  {
    textPosition: "text-center",
    category: "",
    title: "Your Data, Our Expertise",
    description:
      "Unlock data's potential with simple, cost-effective solutions that turn information into actionable insights for smarter decisions.",
    buttonText: "Contact Us",
    buttonLink: "/contact",
  },
];



const servicesArray = [
  {
    icon: <FiBarChart />,
    title: 'Data Strategy Development',
    description: 'We collaboratively craft a data strategy aligned with your business goals, ensuring your data collection and analysis efforts are targeted and impactful.',
  },
  {
    icon: <FiDatabase />,
    title: 'Data Collection & Preparation',
    description: 'Our data specialists meticulously collect raw data from various sources and meticulously clean and prepare it for analysis.',
  },
  {
    icon: <FiTrendingUp />,
    title: 'In-Depth Data Analysis',
    description: 'Utilizing a variety of advanced data analysis techniques, we extract actionable insights hidden within your data.',
    manager: 'Howard Ong',
    role: 'Manager',
  },
  {
    icon: <FiPieChart />,
    title: 'Compelling Data Visualization',
    description: 'We transform complex data sets into clear and understandable charts, graphs, and dashboards for effective communication with stakeholders.',
  },
  {
    icon: <FiFileText />,
    title: 'Data Storytelling',
    description: 'We craft a compelling narrative that translates your data insights into actionable business strategies.',
  },
];


class CreativeLanding extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }
  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }
  stickyHeader() {}
  render() {
    const PostList = BlogContent.slice(0, 5);

    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky");
        document.querySelector(".logo-text").classList.add("logo-text-light");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
        document.querySelector(".logo-text").classList.remove("logo-text-light");
      }
    });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }

    return (
      <Fragment>
        <Helmet pageTitle="Creative Landing" />
        {/* Start Header Area  */}
        <header className="header-area formobile-menu header--fixed default-color">
          <div className="header-wrapper" id="header-wrapper">
            <div className="header-left">
              <div className="logo">
                <a href="/" className="d-flex align-items-center ">
                  <img className="logo-1" src={logoLight} alt="Logo Images"/>
                  <img className="logo-2" src={logoAllDark} alt="Logo Images"/>
                  <span className="px-3 logo-text">Usawa Data Hub</span>
                </a>
              </div>
            </div>
            <div className="header-right">
              <nav className="mainmenunav d-lg-block">
                <Scrollspy
                    className="mainmenu"
                    items={[
                      "home",
                      "about",
                      "service",
                      "contact",
                    ]}
                    currentClassName="is-current"
                    offset={-200}
                >
                  <li className="has-droupdown">
                    <a href="#home">Home</a>
                  </li>
                  <li>
                    <a href="#about">About</a>
                  </li>
                  <li>
                    <a href="#service">Service</a>
                  </li>
                  <li>
                    <a href="#contact">Contact</a>
                  </li>
                </Scrollspy>
              </nav>
              {/* Start Humberger Menu  */}
              <div className="humberger-menu d-block d-lg-none pl--20">
                <span
                  onClick={this.menuTrigger}
                  className="menutrigger text-white"
                >
                  <FiMenu />
                </span>
              </div>
              {/* End Humberger Menu  */}
              <div className="close-menu d-block d-lg-none">
                <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                  <FiX />
                </span>
              </div>
            </div>
          </div>
        </header>
        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div className="slider-activation slider-creative-agency" id="home">
          <div className="bg_image bg_image--28" data-black-overlay="6">
            {SlideList.map((value, index) => (
              <div
                className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center"
                key={index}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className={`inner ${value.textPosition}`}>
                        {value.category ? <span>{value.category}</span> : ""}
                        {value.title ? (
                          <h1 className="title theme-gradient">
                            {value.title}
                          </h1>
                        ) : (
                          ""
                        )}
                        {value.description ? (
                          <p className="description">{value.description}</p>
                        ) : (
                          ""
                        )}
                        {value.buttonText ? (
                          <div className="slide-btn">
                            <a
                              className="rn-button-style--2 btn-primary-color"
                              href={`#contact`}
                            >
                              {value.buttonText}
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* End Slider Area   */}
        {/* Start About Area */}
        <div className="about-area ptb--120 bg_color--5" id="about">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img className="w-100" src={aboutImg} alt="About Images" />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">About Us</h2>
                      <p className="description">
                        At Usawa DataHub, we are passionate about empowering businesses of
                        all sizes to unlock the transformative power of data. We bridge the gap
                        between raw information and actionable insights, fostering data-driven
                        decision-making and propelling growth.
                      </p>
                    </div>
                    <div className="row mt--30">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">Vision</h3>
                          <p>
                            To empower every business to become a data-driven leader, shaping a future
                            fueled by intelligent decision-making and sustainable success.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">Mission</h3>
                          <p>
                            To be your trusted partner in the data journey. We strive to demystify data
                            complexities, providing accessible and cost-effective solutions that empower you
                            to extract maximum value from your information assets.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area */}
        {/* Start Service Area  */}
        <div
            className="service-area creative-service-wrapper ptb--120 bg_color--1"
            id="service"
        >
          <div className="container">
            <div className="row creative-service">
              <div className="col-lg-12">
                <ServiceList
                    item="12"
                    column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
                />
              </div>
            </div>
          </div>

          <div className="rn-slick-dot ptb--120">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="section-title service-style--3 text-left mb--15 mb_sm--0">
                    <h2 className="title">HOW WE CAN HELP YOU</h2>
                    <p>
                      Usawa DataHub empowers you to unlock the true potential of your
                      data. We guide you through every step of the data journey:
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                {servicesArray.map((val, i) => (
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 text-left" key={i}>
                      <a href="/service-details">
                        <div className="service service__style--2">
                          <div className="icon">
                            {val.icon}
                          </div>
                          <div className="content">
                            <h3 className="title">{val.title}</h3>
                            <p>{val.description}</p>
                          </div>
                        </div>
                      </a>
                    </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}










        {/* Start Contact Us */}
        <div className="rn-contact-us ptb--120 bg_color--5" id="contact">
          {/* Start Contact Top Area  */}
          <div className="rn-contact-top-area ptb--120 bg_color--5">
            <div className="container">

              <div className="row">
                {/* Start Single Address  */}
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div className="rn-address">
                    <div className="icon">
                      <FiHeadphones />
                    </div>
                    <div className="inner">
                      <h4 className="title">Contact With Phone Number</h4>
                      <p><a href="tel:+057 254 365 456">+254790953151</a></p>
                      <p><a href="tel:+856 325 652 984">+254746458558</a></p>
                    </div>
                  </div>
                </div>

                {/* End Single Address  */}

                {/* Start Single Address  */}
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                  <div className="rn-address">
                    <div className="icon">
                      <FiMail />
                    </div>
                    <div className="inner">
                      <h4 className="title">Email Address</h4>
                      <p><a href="mailto:admin@gmail.com">info@usawadatahub.com</a></p>
                      <p><a href="mailto:example@gmail.com">jonathan@usawadatahub.com</a></p>
                    </div>
                  </div>
                </div>
                {/* End Single Address  */}

                {/* Start Single Address  */}
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                  <div className="rn-address">
                    <div className="icon">
                      <FiMapPin />
                    </div>
                    <div className="inner">
                      <h4 className="title">Location</h4>
                      <p>P.O.Box 232-00100,Nairobi,<br/>Kenya6</p>
                    </div>
                  </div>
                </div>
                {/* End Single Address  */}

              </div>
            </div>
          </div>
          {/* End Contact Top Area  */}
        </div>
        {/* End Contact Us */}



        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}
        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}

export default CreativeLanding;
