import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";



class Contact extends Component{
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Contact' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                 {/* Start Breadcrump Area */}
                 <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--28"  data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title text-white">Contact With Us</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start Contact Us */}
                <div className="rn-contact-us ptb--120 bg_color--5" id="contact">
                    {/* Start Contact Top Area  */}
                    <div className="rn-contact-top-area ptb--120 bg_color--5">
                        <div className="container">

                            <div className="row">
                                {/* Start Single Address  */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                    <div className="rn-address">
                                        <div className="icon">
                                            <FiHeadphones />
                                        </div>
                                        <div className="inner">
                                            <h4 className="title">Contact With Phone Number</h4>
                                            <p><a href="tel:+057 254 365 456">+254790953151</a></p>
                                            <p><a href="tel:+856 325 652 984">+254746458558</a></p>
                                        </div>
                                    </div>
                                </div>

                                {/* End Single Address  */}

                                {/* Start Single Address  */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                    <div className="rn-address">
                                        <div className="icon">
                                            <FiMail />
                                        </div>
                                        <div className="inner">
                                            <h4 className="title">Email Address</h4>
                                            <p><a href="mailto:admin@gmail.com">info@usawadatahub.com</a></p>
                                            <p><a href="mailto:example@gmail.com">jonathan@usawadatahub.com</a></p>
                                        </div>
                                    </div>
                                </div>
                                {/* End Single Address  */}

                                {/* Start Single Address  */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                    <div className="rn-address">
                                        <div className="icon">
                                            <FiMapPin />
                                        </div>
                                        <div className="inner">
                                            <h4 className="title">Location</h4>
                                            <p>P.O.Box 232-00100,Nairobi,<br/>Kenya6</p>
                                        </div>
                                    </div>
                                </div>
                                {/* End Single Address  */}

                            </div>
                        </div>
                    </div>
                    {/* End Contact Top Area  */}
                </div>
                {/* End Contact Us */}


                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo />

            </React.Fragment>
        )
    }
}
export default Contact
