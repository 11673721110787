import React, { Component, Fragment } from "react";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import ServiceList from "../elements/service/ServiceList";
import BlogContent from "../elements/blog/BlogContent";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Accordion01 from "../elements/Accordion";
import {  FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";

import about from "../assets/images/about/2.webp";
import about2 from "../assets/images/about/1.webp";

const SlideList = [
  {
    textPosition: "text-left",
    bgImage: "bg_image--28",
    category: "",
    title: "Your Data, Our Expertise",
    description:
      "Empowering your business with tailored data solutions that drive growth, streamline decisions, and unlock new opportunities",
    buttonText: "Contact Us",
    buttonLink: "/contact",
  },
  {
    textPosition: "text-left",
    bgImage: "bg_image--28",
    category: "",
    title: "Turn Insights into Action",
    description:
      "Transform your data into actionable strategies that fuel smarter decisions and drive meaningful business outcomes.",
    buttonText: "Contact Us",
    buttonLink: "/contact",
  }
];

class Business extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    var namesItemOne = [
      "The Philosophy Of business analytics",
      "Fast-Track Your business",
      "Lies And Damn Lies About business",
      "The Ultimate Deal On business",
    ];
    var namesItemTwo = [
      "Proof That business Really Works",
      "Here Is What You Should Do For Your business",
      "The Hidden Mystery Behind business",
    ];

    const PostList = BlogContent.slice(0, 3);

    return (
      <Fragment>
        <Helmet pageTitle="Business" />
        <Header />
        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <div className="slider-activation">
            <Slider className="rn-slick-dot dot-light" {...slideSlick}>
              {SlideList.map((value, index) => (
                <div
                  className={`slide slide-style-2 d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                  key={index}
                  data-black-overlay="8"
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className={`inner ${value.textPosition}`}>
                          {value.category ? <span>{value.category}</span> : ""}
                          {value.title ? (
                            <h1 className="title">{value.title}</h1>
                          ) : (
                            ""
                          )}
                          {value.description ? (
                            <p className="description">{value.description}</p>
                          ) : (
                            ""
                          )}
                          {value.buttonText ? (
                            <div className="slide-btn">
                              <a
                                className="rn-button-style--2 btn-solid"
                                href={`${value.buttonLink}`}
                              >
                                {value.buttonText}
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {/* End Slider Area   */}

        {/*
        <div className="rn-brand-area bg_color--5 ptb--60">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div>
      */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">About</h2>
                    <p className="description">
                      At Usawa DataHub, we are passionate about empowering businesses of
                      all sizes to unlock the transformative power of data. We bridge the gap
                      between raw information and actionable insights, fostering data-driven
                      decision-making and propelling growth.
                    </p>
                  </div>
                  <div className="mt--30">
                    <h4>Vision.</h4>
                   <p> To empower every business to become a data-driven leader, shaping a future
                     fueled by intelligent decision-making and sustainable success.</p>
                  </div>
                  <div className="mt--30">
                    <h4>Mission</h4>
                    <p>
                      To be your trusted partner in the data journey. We strive to demystify data
                      complexities, providing accessible and cost-effective solutions that empower you
                      to extract maximum value from your information assets.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <div className="thumbnail position-relative">
                  <img
                      className="w-100"
                      src={about2}
                      alt="About Images"
                  />
                  <ModalVideo
                      channel="custom"
                      isOpen={this.state.isOpen}
                      url="https://res.cloudinary.com/doqeagoqt/video/upload/f_auto:video,q_auto/bwlozltpslh9bwbc1zts"
                      onClose={() => this.setState({ isOpen: false })}
                  />
                  <button
                      className="video-popup position-top-center theme-color"
                      onClick={this.openModal}
                  >
                    <span className="play-icon"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}
        {/* Start Service Area  */}
        <div className="service-area creative-service-wrapper ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--30 mb_sm--0">
                  <h2 className="title">Our Service</h2>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration.
                  </p>
                </div>
              </div>
            </div>
            <div className="row creative-service">
              <div className="col-lg-12">
                <ServiceList
                    item="6"
                    column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
                />
              </div>
            </div>
            <div className="about-button mt--50 d-flex justify-content-center">
              <a className="rn-button-style--2 btn-solid" href="/about">
                See more
              </a>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src={about}
                    alt="About Images"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">HOW WE CAN HELP YOU</h2>
                    <p>
                      Usawa DataHub empowers you to unlock the true potential of your
                      data. We guide you through every step of the data journey
                    </p>
                  </div>
                  <div className="accordion-wrapper mt--30">
                    <Accordion01 />
                  </div>
                  <div className="about-button mt--50">
                    <a className="rn-button-style--2 btn-solid" href="/about">
                      See how it works
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start call To Action  */}
        <CallAction />
        {/* End call To Action  */}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}
export default Business;
