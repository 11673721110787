import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from "react-scroll-up";
import {FiChevronUp, FiHeadphones, FiMail, FiMapPin} from "react-icons/fi";
import Header from "../component/header/Header";

import about from "../assets/images/about/2.webp";
import FooterTwo from "../component/footer/FooterTwo";

class About extends Component {
  render() {
    let title = "About",
      description =
        "  At Usawa DataHub, we are passionate about empowering businesses of\n" +
          "                      all sizes to unlock the transformative power of data. We bridge the gap\n" +
          "                      between raw information and actionable insights, fostering data-driven\n" +
          "                      decision-making and propelling growth.";
    return (
      <React.Fragment>
        <PageHelmet pageTitle="About" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />
        {/* Start Breadcrump Area */}
        <Breadcrumb title={"About"} />
        {/* End Breadcrump Area */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src={about}
                      alt="About Images"
                    />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">{title}</h2>
                      <p className="description">{description}</p>
                    </div>
                    <div className="row mt--30">

                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">Mission</h3>
                          <p>
                            To be your trusted partner in the data journey. We strive to demystify data
                            complexities, providing accessible and cost-effective solutions that empower you
                            to extract maximum value from your information assets.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">Vision</h3>
                          <p>
                            To empower every business to become a data-driven leader, shaping a future
                            fueled by intelligent decision-making and sustainable success.
                          </p>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start Contact Us */}
        <div className="rn-contact-us ptb--120 bg_color--5" id="contact">
          {/* Start Contact Top Area  */}
          <div className="rn-contact-top-area ptb--120 bg_color--5">
            <div className="container">

              <div className="row">
                {/* Start Single Address  */}
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div className="rn-address">
                    <div className="icon">
                      <FiHeadphones />
                    </div>
                    <div className="inner">
                      <h4 className="title">Contact With Phone Number</h4>
                      <p><a href="tel:+057 254 365 456">+254790953151</a></p>
                      <p><a href="tel:+856 325 652 984">+254746458558</a></p>
                    </div>
                  </div>
                </div>

                {/* End Single Address  */}

                {/* Start Single Address  */}
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                  <div className="rn-address">
                    <div className="icon">
                      <FiMail />
                    </div>
                    <div className="inner">
                      <h4 className="title">Email Address</h4>
                      <p><a href="mailto:admin@gmail.com">info@usawadatahub.com</a></p>
                      <p><a href="mailto:example@gmail.com">jonathan@usawadatahub.com</a></p>
                    </div>
                  </div>
                </div>
                {/* End Single Address  */}

                {/* Start Single Address  */}
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                  <div className="rn-address">
                    <div className="icon">
                      <FiMapPin />
                    </div>
                    <div className="inner">
                      <h4 className="title">Location</h4>
                      <p>P.O.Box 232-00100,Nairobi,<br/>Kenya6</p>
                    </div>
                  </div>
                </div>
                {/* End Single Address  */}

              </div>
            </div>
          </div>
          {/* End Contact Top Area  */}
        </div>
        {/* End Contact Us */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp/>
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <FooterTwo/>
      </React.Fragment>
    );
  }
}

export default About;
